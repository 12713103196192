import { MetaWebsite, isBrowser } from '@moonhatch/gatsby-theme-moonhatch';
import React from 'react';

import style from './404.module.scss';

export default ({ location }) => {
  return (
    isBrowser && (
      <div className={style.container}>
        <MetaWebsite
          pageDescription="Page not found."
          pageTitle="Not found"
          pathname={location.pathname}
        />
        <p>The page you are looking for has been removed or relocated.</p>
      </div>
    )
  );
};
